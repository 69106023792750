import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/it/layout"
import SEO from "../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import ReactTimeAgo from 'react-time-ago'
import JavascriptTimeAgo from 'javascript-time-ago'
import it from 'javascript-time-ago/locale/it'
import Microlink from "@microlink/react"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
      query {
          news: allDirectusNews(sort: {order: DESC, fields: datetime}) {
              nodes {
                  datetime
                  link
                  content
              }
          }
          image: file(relativePath: { eq: "news.jpg" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  JavascriptTimeAgo.locale(it)

  const fixDateForAllBrowsers = dateString => dateString.replace(/-/g, '/');

  const seo = {
    title: "News",
    description: "Le ultime news direttamente da Euroconsulting",
  }

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} lang={"it"} url={"/news"} />
      <Container fluid>
        <Row className={"site-content"}>
          <Col md={12} lg={6} xl={5} className={"offset-lg-1"}>
            <h1>News</h1>
            { data.news.nodes.map((news, key) => (
              <Card key={key} className={"my-3 news"}>
                <Card.Body>
                  <Card.Title>Euroconsulting</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted"><ReactTimeAgo date={ fixDateForAllBrowsers(news.datetime) } locale={"it"}/></Card.Subtitle>
                  <Card.Text className={"my-0"}>{ news.content }</Card.Text>
                </Card.Body>
                { news.link ? (
                  <Microlink url={ news.link } size='large'/>
                ) : (<></>)}
              </Card>
            ))}
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5} className={"offset-lg-1 p-0"}>
            <Img fluid={data.image.childImageSharp.fluid} style={{ width: `100%` }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
